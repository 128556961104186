@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-range-start {
  background: linear-gradient(to right, #ffffff 50%, rgb(245, 245, 245) 50%);
}

.gradient-range-end {
  background: linear-gradient(to left, #ffffff 50%, rgb(245, 245, 245) 50%);
}

/* filepond custom styles */
.filepond--panel-root {
  background-color: #ffffff !important;
}

.pip-modal .filepond--wrapper {
  display: none;
}

.filepond--item {
  width: calc(25% - 0.6em);
}

.filepond--list {
  margin-top: 15px !important;
  display: none;
}

.table--full-width {
  width: 100%;
}
